<template>
  <div class="position container">
    <div class="title">账号信息</div>
    <el-form class="form" ref="form" :model="form" label-position="left">
      <el-form-item class="content" label="用户名">
        <span>{{ form.username }}</span>
      </el-form-item>
      <el-form-item class="content" label="联系人">
        <span>{{ form.contact_name }}</span>
      </el-form-item>
      <el-form-item class="content" label="邮箱">
        <span>{{ form.email }}</span>
      </el-form-item>
      <el-form-item class="content" label="联系电话">
        <span>{{ form.phone }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import users from "/src/api/user.js";
export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      users.getUser().then((item) => {
        this.form = item
      });
    },
  },
};
</script>
<style lang="scss">
.position {
  overflow: scroll;
  .form {
    margin: 40px 0 0 80px;
  }
}
.content {
  font-size: 16px;
  span {
    color: #606266;
    width: 90px;
    height: 60px;
    font-size: 16px;
  }
  .el-form-item__label {
    font-weight: 700;
    color: #504b4b;
    width: 90px;
    height: 60px;
    font-size: 16px;
  }
}
</style>
